<template>
  <v-footer class="justify-center white--text" color="black" light>
    <div class="d-flex justify-sm-space-around">
      &copy; {{ new Date().getFullYear() }} HerbsEncyclopedia.info. {{ $t('labels.frontend.all_rights_reserved') }} | {{ $t('labels.frontend.powered_by') }}
    </div>
    <div class="title my-auto">
      <a href="https://esteps.my" target="_blank" class="" style="position: static;">
        <span class="v-btn__content">
          <img src="../../../vendor/tabler/brand/e-steps-logo-light.png" height="20">
        </span>
      </a>
    </div>

    <v-fab-transition>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        :right="!$vuetify.rtl"
        :left="$vuetify.rtl"
        color="green"
        @click="toTop"
      >
        <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-footer>
</template>
<script>
export default {
  name: 'FeFooter',
  data () {
    return {
      fab: false
    }
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }

  }
}
</script>
<style scoped>
  /deep/ a:not([href]):not([tabindex]) {
    color: white !important;
  }

  button:focus {
    outline: unset !important;
  }
</style>
