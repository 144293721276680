export default {
    "ar": {
        "labels": {
            "frontend": {
                "pages": {
                    "herbs": "الأعشاب",
                    "herb_details": "معلومات العشبة"
                },
                "languages": {
                    "ar": "عربي",
                    "en": "أنكليزي"
                },
                "families": "السلاسل",
                "search": "بحث",
                "read_more": "اقرأ المزيد",
                "other_names": "أسماء أخرى",
                "used_parts": "الأجزاء القابلة للاستخدام",
                "description": "الوصف",
                "nutrition_value": "القيمة الغذائية",
                "health_benefits": "الفوائد الصحية",
                "other_uses": "استخدامات أخرى",
                "warnings": "تحذيرات",
                "references": "المراجع",
                "all_rights_reserved": "جميع الحقوق محفوظة",
                "powered_by": "مشغل عن طريق",
                "herbs_encyclopedia": "موسوعة الأعشاب",
                "herbs_encyclopedia_description": "موسوعة الأعشاب تقدم معلومات وتفاصيل عن الأعشاب، الخضار والفواكه. المحتوى كاملاً مدعّم بمصادر موثوقة وتم تدقيقه من قبل إختصاصين في المجال."
            }
        }
    },
    "en": {
        "mails": {
            "layout": {
                "hello": "Hello !",
                "regards": "Regards",
                "trouble": "If you’re having trouble clicking the {action} button, copy and paste the URL below into your web browser :",
                "all_rights_reserved": "All rights reserved."
            },
            "password_reset": {
                "subject": "Password reset",
                "intro": "You are receiving this email because we received a password reset request for your account.",
                "action": "Reset Password",
                "outro": "If you did not request a password reset, no further action is required."
            },
            "email_confirmation": {
                "subject": "Email confirmation",
                "intro": "Email confirmation is required in order to unleash your account.",
                "action": "Confirm my email",
                "outro": "Your account will be limited as long as your email remains not confirmed."
            },
            "contact": {
                "subject": "New contact message",
                "new_contact": "You've got a new contact message. Submission detail :"
            },
            "alert": {
                "subject": "[{app_name}] Exception error",
                "message": "You've got unexpected server exception error which message is : {message}.",
                "trace": "All trace detail :"
            }
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "permissions": {
            "categories": {
                "tags": "Tags",
                "categories": "Categories",
                "links": "References",
                "comments": "Comments",
                "herbs": "Herbs",
                "medical_cases": "Medical Cases",
                "medical_treatments": "Medical Treatments",
                "sliders": "Sliders",
                "advertisements": "Advertisements",
                "clients": "Clients",
                "access": "Access",
                "setting": "Setting"
            },
            "access": {
                "backend": {
                    "display_name": "Backoffice access",
                    "description": "Can access to administration pages."
                },
                "change_password": {
                    "display_name": "Change own password",
                    "description": "Can change his own password"
                }
            },
            "view": {
                "users": {
                    "display_name": "View users",
                    "description": "Can view users."
                },
                "clients": {
                    "display_name": "View clients",
                    "description": "Can view clients."
                },
                "roles": {
                    "display_name": "View roles",
                    "description": "Can view roles."
                },
                "tags": {
                    "display_name": "View tags",
                    "description": "Can view tags."
                },
                "categories": {
                    "display_name": "View categories",
                    "description": "Can view categories."
                },
                "comments": {
                    "display_name": "View comments",
                    "description": "Can view comments."
                },
                "herbs": {
                    "display_name": "View herbs",
                    "description": "Can view herbs."
                },
                "medical_cases": {
                    "display_name": "View medical cases",
                    "description": "Can view medical cases."
                },
                "sliders": {
                    "display_name": "View sliders",
                    "description": "Can view sliders."
                },
                "advertisements": {
                    "display_name": "View advertisements",
                    "description": "Can view advertisements."
                },
                "medical_treatments": {
                    "display_name": "View medical treatments",
                    "description": "Can view medical treatments."
                }
            },
            "create": {
                "users": {
                    "display_name": "Create users",
                    "description": "Can create users."
                },
                "clients": {
                    "display_name": "Create clients",
                    "description": "Can create clients."
                },
                "roles": {
                    "display_name": "Create roles",
                    "description": "Can create roles."
                },
                "tags": {
                    "display_name": "Create tags",
                    "description": "Can create tags."
                },
                "categories": {
                    "display_name": "Create categories",
                    "description": "Can create categories."
                },
                "links": {
                    "display_name": "Create references",
                    "description": "Can create references."
                },
                "herbs": {
                    "display_name": "Create herbs",
                    "description": "Can create herbs."
                },
                "medical_cases": {
                    "display_name": "Create medical cases",
                    "description": "Can create medical cases."
                },
                "sliders": {
                    "display_name": "Create sliders",
                    "description": "Can create sliders."
                },
                "advertisements": {
                    "display_name": "Create advertisements",
                    "description": "Can create advertisements."
                },
                "medical_treatments": {
                    "display_name": "Create medical treatments",
                    "description": "Can create medical treatments."
                }
            },
            "edit": {
                "users": {
                    "display_name": "Edit users",
                    "description": "Can edit users."
                },
                "clients": {
                    "display_name": "Edit clients",
                    "description": "Can edit clients."
                },
                "roles": {
                    "display_name": "Edit roles",
                    "description": "Can edit roles."
                },
                "tags": {
                    "display_name": "Edit tags",
                    "description": "Can edit tags."
                },
                "categories": {
                    "display_name": "Edit categories",
                    "description": "Can edit categories."
                },
                "links": {
                    "display_name": "Edit references",
                    "description": "Can edit references."
                },
                "herbs": {
                    "display_name": "Edit herbs",
                    "description": "Can edit herbs."
                },
                "medical_cases": {
                    "display_name": "Edit medical cases",
                    "description": "Can edit medical cases."
                },
                "sliders": {
                    "display_name": "Edit sliders",
                    "description": "Can edit sliders."
                },
                "advertisements": {
                    "display_name": "Edit advertisements",
                    "description": "Can edit advertisements."
                },
                "medical_treatments": {
                    "display_name": "Edit medical treatments",
                    "description": "Can edit medical treatments."
                }
            },
            "delete": {
                "users": {
                    "display_name": "Delete users",
                    "description": "Can delete users."
                },
                "clients": {
                    "display_name": "Delete clients",
                    "description": "Can delete clients."
                },
                "roles": {
                    "display_name": "Delete roles",
                    "description": "Can delete roles."
                },
                "tags": {
                    "display_name": "Delete tags",
                    "description": "Can delete tags."
                },
                "categories": {
                    "display_name": "Delete categories",
                    "description": "Can delete categories."
                },
                "links": {
                    "display_name": "Delete references",
                    "description": "Can delete references."
                },
                "herbs": {
                    "display_name": "Delete herbs",
                    "description": "Can delete herbs."
                },
                "medical_cases": {
                    "display_name": "Delete medical cases",
                    "description": "Can delete medical cases."
                },
                "sliders": {
                    "display_name": "Delete sliders",
                    "description": "Can delete sliders."
                },
                "advertisements": {
                    "display_name": "Delete advertisements",
                    "description": "Can delete advertisements."
                },
                "medical_treatments": {
                    "display_name": "Delete medical treatments",
                    "description": "Can delete medical treatments."
                }
            },
            "impersonate": {
                "display_name": "Impersonate user",
                "description": "Can take ownership of others user identities. Useful for tests."
            },
            "others": {
                "comments": {
                    "approve": {
                        "description": "Can approve comments",
                        "display_name": "Approve Comments"
                    },
                    "reject": {
                        "description": "Can reject comments",
                        "display_name": "Reject Comments"
                    }
                }
            }
        },
        "buttons": {
            "cancel": "Cancel",
            "save": "Save",
            "close": "Close",
            "create": "Create",
            "delete": "Delete",
            "confirm": "Confirm",
            "show": "Show",
            "edit": "Edit",
            "print": "Print",
            "update": "Update",
            "view": "View",
            "yes": "Yes",
            "no": "No",
            "mark_as_done": "Mark As Done",
            "generate": "Generate",
            "preview": "Preview",
            "back": "Back",
            "send": "Send",
            "login-as": "Login as {name}",
            "apply": "Apply",
            "search": "Search",
            "reset": "Reset",
            "execute": "Execute",
            "approve": "Approve",
            "delete_sign": "Delete sign",
            "view_signed_contract": "View Signed Contract",
            "show_hide_filters": "Show/Hide Filters",
            "show_hide_columns": "Show/Hide Columns",
            "drop_file": "Drop File",
            "add_image": "Add image",
            "add_herb": "Add herb",
            "drop_image": "Drop image",
            "drop_herb": "Drop herb",
            "expand": "Expand",
            "resend_confirmation": "Resend Confirmation",
            "users": {
                "create": "Create user"
            },
            "roles": {
                "create": "Create role"
            },
            "tags": {
                "create": "Create family"
            },
            "categories": {
                "create": "Create category"
            },
            "links": {
                "create": "Create reference"
            },
            "comments": {
                "approve": "Approve",
                "reject": "Reject"
            },
            "herbs": {
                "create": "Create herb"
            },
            "medical_cases": {
                "create": "Create medical case"
            },
            "sliders": {
                "create": "Create slider"
            },
            "advertisements": {
                "create": "Create advertisement"
            },
            "medical_treatments": {
                "create": "Create home remedy"
            }
        },
        "passwords": {
            "password": "Passwords must be at least six characters and match the confirmation.",
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "logs": {
            "backend": {
                "users": {
                    "created": "User ID {user} created",
                    "updated": "User ID {user} updated",
                    "deleted": "User ID {user} deleted"
                }
            }
        },
        "alerts": {
            "backend": {
                "users": {
                    "created": "User created",
                    "updated": "User updated",
                    "deleted": "User deleted",
                    "bulk_destroyed": "Selected users deleted",
                    "bulk_enabled": "Selected users enabled",
                    "bulk_disabled": "Selected users disabled",
                    "status_updated": "User status updated",
                    "profile_updated": "Your profile updated",
                    "confirmation_resent": "Confirmation resent successfully"
                },
                "roles": {
                    "created": "Role created",
                    "updated": "Role updated",
                    "deleted": "Role deleted"
                },
                "clients": {
                    "updated": "Client updated",
                    "deleted": "Client deleted"
                },
                "tags": {
                    "created": "Tag created",
                    "updated": "Tag updated",
                    "deleted": "Tag deleted"
                },
                "categories": {
                    "created": "Category created",
                    "updated": "Category updated",
                    "deleted": "Category deleted"
                },
                "links": {
                    "created": "Reference created",
                    "updated": "Reference updated",
                    "deleted": "Reference deleted"
                },
                "comments": {
                    "approved": "Comment approved",
                    "rejected": "Comment rejected"
                },
                "herbs": {
                    "created": "Herb created",
                    "updated": "Herb updated",
                    "deleted": "Herb deleted"
                },
                "medical_cases": {
                    "created": "Medical case created",
                    "updated": "Medical case updated",
                    "deleted": "Medical case deleted"
                },
                "sliders": {
                    "created": "Slider created",
                    "updated": "Slider updated",
                    "deleted": "Slider deleted",
                    "status_updated": "Slider status updated"
                },
                "advertisements": {
                    "created": "Advertisement created",
                    "updated": "Advertisement updated",
                    "deleted": "Advertisement deleted",
                    "status_updated": "Advertisement status updated"
                },
                "medical_treatments": {
                    "created": "Medical treatment created",
                    "updated": "Medical treatment updated",
                    "deleted": "Medical treatment deleted"
                }
            }
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                },
                "herbs.*.desc.en": {
                    "required_if": "English description is required when Arabic one is omitted",
                    "required_with": "English description is required with name is in english"
                },
                "herbs.*.desc.ar": {
                    "required_if": "Arabic description is required when english one is omitted",
                    "required_with": "Arabic description is required with name is in arabic"
                }
            },
            "attributes": {
                "id": "ID",
                "display_name": "Display name",
                "username": "Pseudo",
                "email": "Email",
                "first_name": "Firstname",
                "last_name": "Lastname",
                "password": "Password",
                "password_confirmation": "Confirm password",
                "old_password": "Old password",
                "new_password": "New password",
                "new_password_confirmation": "Confirm new password",
                "postal_code": "Postal code",
                "city": "City",
                "country": "Country",
                "address": "Address",
                "phone": "Phone",
                "mobile": "Mobile",
                "age": "Age",
                "sex": "Sex",
                "gender": "Gender",
                "day": "Day",
                "month": "Month",
                "year": "Year",
                "hour": "Hour",
                "minute": "Minute",
                "second": "Second",
                "content": "Content",
                "summary": "Summary",
                "excerpt": "Excerpt",
                "date": "Date",
                "time": "Time",
                "available": "Available",
                "size": "Size",
                "roles": "Roles",
                "permissions": "Permissions",
                "active": "Active",
                "confirmed": "Confirmed",
                "message": "Message",
                "g-recaptcha-response": "Captcha",
                "locale": "Localization",
                "route": "Route",
                "url": "URL alias",
                "form_type": "Form type",
                "form_data": "Form data",
                "recipients": "Recipients",
                "source_path": "Original path",
                "target_path": "Target path",
                "redirect_type": "Redirect type",
                "timezone": "Timezone",
                "order": "Display order",
                "image": "Image",
                "nutritional_value_image": "Nutritional Value Image",
                "status": "Status",
                "pinned": "Pinned",
                "promoted": "Promoted",
                "body": "Body",
                "tags": "Families",
                "published_at": "Publish at",
                "unpublished_at": "Unpublish at",
                "description": "Description",
                "description.ar": "Arabic Description",
                "description.en": "English Description",
                "description_ar": "Arabic Description",
                "description_en": "English Description",
                "name": "Name",
                "name.ar": "Arabic Name",
                "name.en": "English Name",
                "name_ar": "Arabic Name",
                "name_en": "English Name",
                "title": "Title",
                "title.ar": "Arabic Title",
                "title.en": "English Title",
                "title_ar": "Arabic Title",
                "title_en": "English Title",
                "featured": "Featured",
                "type": "Type",
                "icon": "Icon",
                "link": "Link",
                "other_names": "Other Names",
                "other_names_ar": "Arabic Other Names",
                "other_names_en": "English Other Names",
                "other_names.ar": "Arabic Other Names",
                "other_names.en": "English Other Names",
                "used_parts": "Used Parts",
                "used_parts_ar": "Arabic Used Parts",
                "used_parts_en": "English Used Parts",
                "used_parts.ar": "Arabic Used Parts",
                "used_parts.en": "English Used Parts",
                "chemical_composition": "Nutritional Value",
                "chemical_composition_ar": "Arabic Nutritional Value",
                "chemical_composition_en": "English Nutritional Value",
                "chemical_composition.ar": "Arabic Nutritional Value",
                "chemical_composition.en": "English Nutritional Value",
                "medical_usages": "Health Benefits",
                "medical_usages_ar": "Arabic Health Benefits",
                "medical_usages_en": "English Health Benefits",
                "medical_usages.ar": "Arabic Health Benefits",
                "medical_usages.en": "English Health Benefits",
                "other_usages": "Other Usages",
                "other_usages_ar": "Arabic Other Usages",
                "other_usages_en": "English Other Usages",
                "other_usages.ar": "Arabic Other Usages",
                "other_usages.en": "English Other Usages",
                "warnings": "Warnings",
                "warnings_ar": "Arabic Warnings",
                "warnings_en": "English Warnings",
                "warnings.ar": "Arabic Warnings",
                "warnings.en": "English Warnings",
                "category": "Category",
                "tag": "Family",
                "cover_image": "Cover Image",
                "herbs": "Herbs",
                "herb": "Herb",
                "language": "Language",
                "target_type": "Target Type",
                "target": "Target",
                "other_info": "Other Names",
                "other_info_ar": "Arabic Other Information",
                "other_info_en": "English Other Information",
                "other_info.ar": "Arabic Other Information",
                "other_info.en": "English Other Information",
                "images_info": {
                    "ar_title": "Arabic Title",
                    "en_title": "English Title",
                    "link": "Link",
                    "copyright": "Copyright",
                    "file": "Image"
                },
                "date_of_birth": "Date Of Birth",
                "join_date": "Join Date",
                "subscribed": "Subscribed",
                "with_notification": "With Notification",
                "platform": "Platform",
                "avatar": "Avatar",
                "notification_token": "Token",
                "about": "About",
                "herbs.*.herb": "Herb",
                "comment": "Comment",
                "commenter": "Commenter",
                "commented_at": "Commented at",
                "subscription": "Subscription"
            }
        },
        "exceptions": {
            "general": "Server exception",
            "not_found": "Item not found",
            "unauthorized": "Action not allowed",
            "backend": {
                "users": {
                    "create": "Error on user creation",
                    "update": "Error on user updating",
                    "delete": "Error on user deletion",
                    "first_user_cannot_be_edited": "You cannot edit super admin user",
                    "first_user_cannot_be_disabled": "Super admin user cannot be disabled",
                    "first_user_cannot_be_destroyed": "Super admin user cannot be deleted",
                    "first_user_cannot_be_impersonated": "Super admin user cannot be impersonated",
                    "cannot_set_superior_roles": "You cannot attribute roles superior to yours"
                },
                "roles": {
                    "create": "Error on role creation",
                    "update": "Error on role updating",
                    "delete": "Error on role deletion"
                },
                "tags": {
                    "create": "Error on tag creation",
                    "update": "Error on tag updating",
                    "delete": "Error on tag deletion",
                    "already_exist": "A tag with same name already exist"
                },
                "categories": {
                    "create": "Error on category creation",
                    "update": "Error on category updating",
                    "delete": "Error on category deletion",
                    "already_exist": "A category with same name already exist"
                },
                "links": {
                    "create": "Error on reference creation",
                    "update": "Error on reference updating",
                    "delete": "Error on reference deletion",
                    "already_exist": "A reference with same title already exist"
                },
                "herbs": {
                    "create": "Error on herb creation",
                    "update": "Error on herb updating",
                    "delete": "Error on herb deletion",
                    "already_exist": "A herb with same title already exist"
                },
                "medical_cases": {
                    "create": "Error on medical case creation",
                    "update": "Error on medical case updating",
                    "delete": "Error on medical case deletion"
                },
                "sliders": {
                    "create": "Error on slider creation",
                    "update": "Error on slider updating",
                    "delete": "Error on slider deletion"
                },
                "advertisements": {
                    "create": "Error on advertisement creation",
                    "update": "Error on advertisement updating",
                    "delete": "Error on advertisement deletion"
                },
                "medical_treatments": {
                    "create": "Error on medical treatment creation",
                    "update": "Error on medical treatment updating",
                    "delete": "Error on medical treatment deletion"
                }
            }
        },
        "labels": {
            "language": "Language",
            "actions": "Actions",
            "general": "General",
            "no_results": "No results available",
            "search": "Search",
            "validate": "Validate",
            "choose_file": "Choose File",
            "no_file_chosen": "No file chosen",
            "are_you_sure": "Are you sure ?",
            "delete_image": "Delete image",
            "yes": "Yes",
            "no": "No",
            "add_new": "Add",
            "export": "Export",
            "more_info": "More info",
            "author": "Author",
            "author_id": "Author ID",
            "last_access_at": "Last access at",
            "published_at": "Published at",
            "created_at": "Created at",
            "updated_at": "Updated at",
            "deleted_at": "Deleted at",
            "no_value": "No value",
            "upload_image": "Upload image",
            "anonymous": "Anonymous",
            "all_rights_reserved": "All rights reserved.",
            "filters": "Filters",
            "with": "with",
            "by": "by",
            "comments": "Comments",
            "datatables": {
                "no_results": "No results available",
                "no_matched_results": "No matched results available",
                "show_per_page": "Show",
                "entries_per_page": "entries per page",
                "search": "Search",
                "infos": "Showing {offset_start} to {offset_end} of {total} entries"
            },
            "morphs": {
                "post": "Post, identity {id}",
                "user": "User, identity {id}"
            },
            "auth": {
                "disabled": "Your account has been disabled."
            },
            "http": {
                "403": {
                    "title": "Access Denied",
                    "description": "Sorry, but you do not have permissions to access this page."
                },
                "404": {
                    "title": "Page Not Found",
                    "description": "Sorry, but the page you were trying to view does not exist."
                },
                "500": {
                    "title": "Server Error",
                    "description": "Sorry, but the server has encountered a situation it doesn't know how to handle. We'll fix this as soon as possible."
                }
            },
            "localization": {
                "en": "English",
                "fr": "French",
                "es": "Spanish"
            },
            "placeholders": {
                "route": "Select a valid internal route",
                "tags": "Select or create a families"
            },
            "cookieconsent": {
                "message": "This website uses cookies to ensure you get the best experience on our website.",
                "dismiss": "Got it !"
            },
            "descriptions": {
                "allowed_image_types": "Allowed types: png gif jpg jpeg.",
                "allowed_documents_types": "Allowed file types: png jpg jpeg pdf.",
                "allowed_pay_leave_values": "0 value means no pay leave<br>1-30 means number of days<br>more than 30 means fixed value"
            },
            "user": {
                "dashboard": "Dashboard",
                "remember": "Remember",
                "login": "Login",
                "logout": "Logout",
                "password_forgot": "Forget password ?",
                "send_password_link": "Send reset password link",
                "password_reset": "Password Reset",
                "register": "Register",
                "space": "My space",
                "settings": "Settings",
                "account": "My account",
                "profile": "My profile",
                "avatar": "Avatar",
                "online": "Online",
                "edit_profile": "Edit my profile",
                "change_password": "Change my password",
                "delete": "Delete my account",
                "administration": "Administration",
                "member_since": "Member since {date}",
                "profile_updated": "Profile successfully updated.",
                "password_updated": "Password successfully updated.",
                "email_confirmation_sended": "Mail confirmation sended.",
                "email_confirmed": "Email successfully confirmed.",
                "super_admin": "Super administrateur",
                "account_delete": "<p>This action will delete entirely your account from this site as well as all associated data.</p>",
                "account_deleted": "Account successfully deleted",
                "titles": {
                    "space": "My space",
                    "account": "My account"
                }
            },
            "alerts": {
                "login_as": "You are actually logged as <strong>{name}</strong>, you can logout as <a href=\"{route}\" data-turbolinks=\"false\">{admin}</a>.",
                "not_confirmed": "Your account will be in limited mode as long as your email remains not confirmed. <a href=\"{route}\">Click here</a> in order to resend mail confirmation."
            },
            "backend": {
                "dashboard": {
                    "new_posts": "New posts",
                    "pending_posts": "Pending posts",
                    "published_posts": "Published posts",
                    "active_users": "Active users",
                    "form_submissions": "Submissions",
                    "last_posts": "Last posts",
                    "last_published_posts": "Last publications",
                    "last_pending_posts": "Last pending posts",
                    "last_new_posts": "Last new posts",
                    "all_posts": "All posts"
                },
                "new_menu": {
                    "user": "New user",
                    "role": "New role",
                    "herb": "New herb",
                    "reference": "New Reference",
                    "medical_case": "New Medical Case",
                    "medical_treatment": "New Home Remedy"
                },
                "sidebar": {
                    "content": "Content management",
                    "access": "Access management"
                },
                "titles": {
                    "administration": "Administration",
                    "dashboard": "Dashboard"
                },
                "target_types": {
                    "Herb": "Herb",
                    "Category": "Category",
                    "MedicalCase": "Medical Case",
                    "MedicalTreatment": "Home Remedy",
                    "External": "External",
                    "Empty": "Empty"
                },
                "users": {
                    "titles": {
                        "menu": "Users",
                        "main": "User management",
                        "index": "User list",
                        "create": "User creation",
                        "edit": "User modification",
                        "profile": "My profile"
                    },
                    "actions": {
                        "destroy": "Delete selected users",
                        "enable": "Enable selected users",
                        "disable": "Disable selected users"
                    }
                },
                "clients": {
                    "titles": {
                        "menu": "Clients",
                        "main": "Client management",
                        "index": "Client list",
                        "create": "Client creation",
                        "edit": "Client modification"
                    }
                },
                "roles": {
                    "titles": {
                        "menu": "Roles",
                        "main": "Role management",
                        "index": "Role list",
                        "create": "Role creation",
                        "edit": "Role modification"
                    }
                },
                "tags": {
                    "titles": {
                        "menu": "Families",
                        "main": "Family management",
                        "index": "Family list",
                        "create": "Family creation",
                        "edit": "Family modification"
                    }
                },
                "categories": {
                    "titles": {
                        "menu": "Categories",
                        "main": "Category management",
                        "index": "Category list",
                        "create": "Category creation",
                        "edit": "Category modification"
                    }
                },
                "links": {
                    "titles": {
                        "menu": "References",
                        "main": "Reference management",
                        "index": "Reference list",
                        "create": "Reference creation",
                        "edit": "Reference modification"
                    }
                },
                "comments": {
                    "titles": {
                        "menu": "Comments",
                        "main": "Comment management",
                        "index": "Comment list",
                        "approve": "Comment approve",
                        "reject": "Comment reject"
                    }
                },
                "herbs": {
                    "titles": {
                        "menu": "Herbs",
                        "main": "Herb management",
                        "index": "Herb list",
                        "info": "Herb information",
                        "create": "Herb creation",
                        "edit": "Herb modification",
                        "images_gallery": "Images Gallery"
                    }
                },
                "medical_cases": {
                    "titles": {
                        "menu": "Medical Cases",
                        "main": "Medical case management",
                        "index": "Medical case list",
                        "create": "Medical case creation",
                        "edit": "Medical case modification"
                    }
                },
                "sliders": {
                    "titles": {
                        "menu": "Sliders",
                        "main": "Slider management",
                        "index": "Slider list",
                        "create": "Slider creation",
                        "edit": "Slider modification"
                    }
                },
                "advertisements": {
                    "titles": {
                        "menu": "Advertisements",
                        "main": "Ads management",
                        "index": "Advertisement list",
                        "create": "Advertisement creation",
                        "edit": "Advertisement modification"
                    }
                },
                "medical_treatments": {
                    "titles": {
                        "menu": "Home Remedies",
                        "main": "Home remedy management",
                        "index": "Home remedy list",
                        "create": "Home remedy creation",
                        "edit": "Home remedy modification"
                    }
                }
            },
            "frontend": {
                "pages": {
                    "herbs": "Herbs",
                    "herb_details": "Herb information"
                },
                "languages": {
                    "ar": "Arabic",
                    "en": "English"
                },
                "families": "Families",
                "search": "Search",
                "read_more": "Read More",
                "other_names": "Other names",
                "used_parts": "Used parts",
                "description": "Description",
                "nutrition_value": "Nutrition value",
                "health_benefits": "Health Benefits",
                "other_uses": "Other Uses",
                "warnings": "Warnings",
                "references": "References",
                "all_rights_reserved": "All rights reserved",
                "powered_by": "Powered by",
                "herbs_encyclopedia": "Herbs Encyclopedia",
                "herbs_encyclopedia_description": "Herbs Encyclopedia provides information and details about herbs, vegetables, and fruits.All content is supported by trusted references and is reviewed by specialists."
            }
        }
    }
}
