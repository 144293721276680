import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

// Containers

// Views
import Herbs from '../views/Herbs'
import Herb from '../views/Herb'

Vue.use(Router)
Vue.use(Meta)

export function createRouter (base, i18n) {
  const router = new Router({
    mode: 'history',
    base: base,
    routes: [
      {
        path: '/',
        name: 'frontend.herbs',
        component: Herbs,
        meta: {
          label: i18n.t('labels.frontend.pages.herbs')
        }
      },
      {
        path: '/herbs/:id/:slug?',
        props: true,
        name: 'frontend.herbs.details',
        component: Herb,
        meta: {
          label: i18n.t('labels.frontend.pages.herb_details')
        }
      }

    ]
  })

  return router
}
