<template>
  <v-app id="keep">
    <v-app-bar
      app
      class="herbs_header"
      clipped-left
    >
      <v-app-bar-nav-icon @click="drawer = !drawer" ></v-app-bar-nav-icon>
      <span class="title ml-3 mr-5">
        <router-link :to="{name: 'frontend.herbs'}">
          <span class="v-btn__content hidden-sm-and-down">
            <img :src="`/images/logo_${$app.locale}.png`" width="160" alt="logo">
          </span>
          <span class="v-btn__content hidden-md-and-up">
            <img src="../../../vendor/tabler/brand/logo-symbol.png" alt="logo" width="40px">
          </span>
        </router-link>
      </span>
      <v-text-field
        single-line
        color="green"
        flat
        hide-details
        :label="$t('labels.frontend.search')"
        prepend-inner-icon="search"
        v-model="search"
        v-debounce:1s="getSearchResults"
      ></v-text-field>
      <v-spacer>
        <div class="d-flex justify-sm-space-around">
          <div class="title my-auto">
            <a href="https://play.google.com/store/apps/details?id=com.e_steps.herbs&amp;hl=en" target="_blank" class="" style="position: static;">
              <span class="v-btn__content hidden-sm-and-down">
                <img src="../../../vendor/tabler/brand/google_play.png" width="150">
              </span>
              <span class="v-btn__content hidden-md-and-up">
                <img src="../../../vendor/tabler/brand/google-play-icon.png" width="50">
              </span>
            </a>
          </div>
          <div class="title my-auto">
            <a href="https://apps.apple.com/us/app/herbs-encyclopedia/id1501804652" target="_blank" class="" style="position: static;">
              <span class="v-btn__content hidden-sm-and-down">
                <img src="../../../vendor/tabler/brand/apple_store.jpg" width="150">
              </span>
              <span class="v-btn__content hidden-md-and-up">
                <img src="../../../vendor/tabler/brand/apple_store_icon.png" width="50">
              </span>
            </a>
          </div>
          <div class="title my-auto">
            <a :href="$app.locale === 'ar' ? '/en' : '/ar'" style="position: static;">
              <span class="v-btn__content hidden-sm-and-down">
                {{ $app.locale === 'ar' ? $t('labels.frontend.languages.en') : $t('labels.frontend.languages.ar') }}
              </span>
            </a>
          </div>
          <div class="title my-auto hidden-sm-and-down">
            <v-btn
              v-for="(social, i) in socials"
              :key="i"
              :color="social.color"
              :href="social.link"
              target="_blank"
              fab
              icon
              large
            >
              <v-icon>{{ social.icon }}</v-icon>
            </v-btn>
          </div>
        </div>

      </v-spacer>
      <v-speed-dial
        class="hidden-md-and-up"
        v-model="fab"
        :top="true"
        :right="true"
        direction="bottom"
        :open-on-hover="false"
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn
            v-model="fab"
            color="green"
            dark
            fab
          >
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-link</v-icon>
          </v-btn>
        </template>
        <v-btn
          v-for="(social, i) in socials"
          :key="i"
          :color="social.color"
          :href="social.link"
          target="_blank"
          fab
          icon
          large
        >
          <v-icon>{{ social.icon }}</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      :right="this.$vuetify.rtl"
      color="green lighten-4"
    >
      <v-list
        dense
        class="green lighten-4"
      >
        <v-row
          align="center"
        >
          <v-col cols="6">
            <v-subheader>
              {{ $t('labels.frontend.families') }}</v-subheader>
          </v-col>
        </v-row>
        <v-list-item-group v-model="tag_id" color="green">

          <template v-for="(item, i) in families">
            <v-list-item
              :key="i"
              link
              :value="item.id"
            >
              <v-list-item-avatar size="36"><v-img :src="item.icon_path" :lazy-src="item.icon_path"
              ></v-img></v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="item.count > 0">
                <v-chip color="green" x-small>{{ item.count }}</v-chip>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-content>
      <!--      <Adsense-->
      <!--        data-ad-client="ca-pub-5830351009376920"-->
      <!--        data-ad-slot="0000000000">-->
      <!--      </adsense>-->
      <!--      <InArticleAdsense-->
      <!--        data-ad-client="ca-pub-0000000000000000"-->
      <!--        data-ad-slot="0000000000">-->
      <!--      </InArticleAdsense>-->
      <v-container
        class="lighten-4 fill-height"
        v-if="herbs.length > 0"
      >
        <v-row>
          <v-col :xs="12" :sm="12" :md="6" :lg="4" v-for="(herb, index) in herbs" :key="index" class="d-flex flex-column">
            <v-hover
              v-slot:default="{ hover }"
              open-delay="200"
            >
              <v-card
                :elevation="hover ? 16 : 2"
                class="mx-auto flex "
              >
                <v-list-item>
                  <v-list-item-avatar><v-img :src="herb.category_image" :lazy-src="herb.category_image"></v-img></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="headline">{{ herb.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ herb.family }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-img
                  :src="herb.image"
                  :lazy-src="herb.image"
                  height="194"
                  contain
                ></v-img>

                <v-card-text>
                  {{ herb.description.split(" ").splice(0,50).join(" ") + "..." }}
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    text
                    :to="{name: 'frontend.herbs.details', params: {id: herb.id, slug: herb.slug}}"
                    color="#4CAF50"
                  >
                    {{ $t('labels.frontend.read_more') }}
                  </v-btn>

                  <!--                  <v-btn-->
                  <!--                    text-->
                  <!--                    color="deep-purple accent-4"-->
                  <!--                  >-->
                  <!--                    Bookmark-->
                  <!--                  </v-btn>-->
                  <!--                  <v-spacer></v-spacer>-->
                  <!--                  <v-btn icon>-->
                  <!--                    <v-icon>mdi-heart</v-icon>-->
                  <!--                  </v-btn>-->
                  <!--                  <v-btn icon>-->
                  <!--                    <v-icon>mdi-share-variant</v-icon>-->
                  <!--                  </v-btn>-->
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-col>
          <v-row justify="center">
            <scroll-loader :loader-method="getMoreHerbs" :loader-disable="disable">
            </scroll-loader>
          </v-row>
        </v-row>
      </v-container>
      <v-container
        class="text-center"
        fill-height
        style="height: calc(100vh - 58px);"
        v-else-if="no_results"
      >
        <v-row align="center">
          <v-col>
            <h1 class="display-2 green--text">Whoops, No results found</h1>

            <p>Try to change search keyword or search by families using <a class="link info--text" @click="drawer = true">left side drawer</a></p>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
    <fe-footer></fe-footer>
  </v-app>
</template>

<script>
import Vue from 'vue'

import axios from 'axios'
import ScrollLoader from 'vue-scroll-loader'
import vueDebounce from 'vue-debounce'
import FeFooter from './FeFooter'

Vue.use(vueDebounce)
Vue.use(ScrollLoader)

export default {
  name: 'Herb',
  metaInfo () {
    return {
      title: `${this.$t('labels.frontend.pages.herbs')} | ${this.$t('labels.frontend.herbs_encyclopedia')}`,
      meta: [
        { vmid: 'description', name: 'description', content: this.$t('labels.frontend.herbs_encyclopedia_description') }
      ]
    }
  },
  components: { FeFooter },
  props: {
    source: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    drawer: false,
    herbs: [],
    families: [],
    disable: false,
    page: 0,
    search: '',
    no_results: false,
    tag_id: null,
    family: null,
    fab: null,
    socials: [
      {
        icon: 'mdi-facebook',
        color: 'indigo',
        link: 'https://www.facebook.com/HerbsEncyclopedia/'
      },
      {
        icon: 'mdi-instagram',
        color: 'red lighten-3',
        link: 'https://www.instagram.com/herbsencyclopedia/'
      },
      {
        icon: 'mdi-twitter',
        color: '#26c6da',
        link: 'https://twitter.com/herbs_app'
      }
    ]
  }),
  watch: {
    tag_id () {
      this.search = null
      this.getMatchingHerbs()
    }
  },
  async created () {
    if (this.$route.query.search && this.$route.query.search !== '') this.search = this.$route.query.search
    this.getMoreHerbs()
    this.getFamilies()
  },
  methods: {
    async getFamilies () {
      let { data } = await axios.get(this.$app.route(`web.api.families.search`))
      this.families = data.data
    },
    async getMoreHerbs (reset = false) {
      this.no_results = false
      this.page++
      let { data } = await axios.get(this.$app.route(`web.api.herbs.search`),
        { params: { page: this.page, search: this.search, tag_id: this.tag_id, arrange_by: 'newest' }
        }
      )
      if (reset) this.herbs = []
      this.herbs = [...this.herbs, ...data.data]
      this.disable = this.page === data.meta.pagination.total_pages
      if (this.herbs.length === 0) this.no_results = true
    },
    getSearchResults () {
      this.tag_id = null
      this.getMatchingHerbs()
    },
    async getMatchingHerbs () {
      this.page = 0
      this.getMoreHerbs(true)
    }
  }
}
</script>

<style>
    #keep .v-navigation-drawer__border {
        display: none
    }
    .herbs_header {
        left: 0 !important;;
        right: 0 !important;;
    }
</style>
