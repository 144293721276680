<template>
  <v-app id="keep">
    <v-app-bar
      app
      clipped-left
    >
      <span class="title ml-3 mr-5">
        <router-link :to="{name: 'frontend.herbs'}">
          <span class="v-btn__content hidden-sm-and-down">
            <span class="v-btn__content hidden-sm-and-down">
              <img :src="`/images/logo_${$app.locale}.png`" width="160" alt="logo">
            </span>
          </span>
          <span class="v-btn__content hidden-md-and-up">
            <img src="../../../vendor/tabler/brand/logo-symbol.png" alt="logo" width="40px">
          </span>
        </router-link>
      </span>
      <v-text-field
        single-line
        flat
        color="green"
        hide-details
        :label="$t('labels.frontend.search')"
        prepend-inner-icon="search"
        v-model="search"
        @change="goToSearch"
      ></v-text-field>

      <v-spacer>
        <div class="d-flex justify-sm-space-around">
          <div class="title my-auto">
            <a href="https://play.google.com/store/apps/details?id=com.e_steps.herbs&amp;hl=en" target="_blank" class="" style="position: static;">
              <span class="v-btn__content hidden-sm-and-down">
                <img src="../../../vendor/tabler/brand/google_play.png" width="150">
              </span>
              <span class="v-btn__content hidden-md-and-up">
                <img src="../../../vendor/tabler/brand/google-play-icon.png" width="50">
              </span>
            </a>
          </div>
          <div class="title my-auto">
            <a href="https://apps.apple.com/us/app/herbs-encyclopedia/id1501804652" target="_blank" class="" style="position: static;">
              <span class="v-btn__content hidden-sm-and-down">
                <img src="../../../vendor/tabler/brand/apple_store.jpg" width="150">
              </span>
              <span class="v-btn__content hidden-md-and-up">
                <img src="../../../vendor/tabler/brand/apple_store_icon.png" width="50">
              </span>
            </a>
          </div>
          <div class="title my-auto">
            <a :href="$app.locale === 'ar' ? '/en' : '/ar'" style="position: static;">
              <span class="v-btn__content hidden-sm-and-down">
                {{ $app.locale === 'ar' ? $t('labels.frontend.languages.en') : $t('labels.frontend.languages.ar') }}
              </span>
            </a>
          </div>
          <div class="title my-auto hidden-sm-and-down">
            <v-btn
              v-for="(social, i) in socials"
              :key="i"
              :color="social.color"
              :href="social.link"
              target="_blank"
              fab
              icon
              large
            >
              <v-icon>{{ social.icon }}</v-icon>
            </v-btn>
          </div>
        </div>

      </v-spacer>
      <v-speed-dial
        class="hidden-md-and-up"
        v-model="fab"
        :top="true"
        :right="true"
        direction="bottom"
        :open-on-hover="false"
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn
            v-model="fab"
            color="green"
            dark
            fab
          >
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-link</v-icon>
          </v-btn>
        </template>
        <v-btn
          v-for="(social, i) in socials"
          :key="i"
          :color="social.color"
          :href="social.link"
          target="_blank"
          fab
          icon
          large
        >
          <v-icon>{{ social.icon }}</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-app-bar>
    <v-content>
      <v-overlay v-if="herb === null" absolute color="green lighten-4">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-container v-else
                   class="fluid px-0"
      >
        <v-row>
          <v-col cols="7" sm="7" offset="1">
            <div class="display-3 pb-3"> {{ herb.name }} </div>
          </v-col>
          <v-col cols="4" sm="2">
            <v-chip
              class="ma-2"
              color="green"
              text-color="white"
            >
              <v-avatar left>
                <v-icon class="rtl">mdi-file-tree</v-icon>
              </v-avatar>
              <h4>
                {{ herb.family }}
              </h4>
            </v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" sm="7" offset="1">
            <p class="display-1">{{ $t('labels.frontend.other_names') }}</p>
            <p class="body-1"> {{ herb.other_names }} </p>
            <p class="display-1">{{ $t('labels.frontend.used_parts') }}</p>
            <p class="body-1"> {{ herb.used_parts }} </p>
          </v-col>
          <v-col cols="12" sm="3">
            <v-img :src="herb.image" max-height="200" max-width="300" contain class="mx-auto"></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" sm="7" offset="1">
            <p class="display-1">{{ $t('labels.frontend.description') }}</p>
            <p class="body-1 justification" v-html="herb.description"></p>
            <v-carousel
              height="400"
              show-arrows-on-hover
            >
              <v-carousel-item
                v-for="(slider,i) in herb.slider"
                :key="i"
                :src="slider"
                reverse-transition="fade-transition"
                transition="fade-transition"
              ></v-carousel-item>
            </v-carousel>
            <p style="padding-top: 20px" class="display-1" v-if="!herb.nutritional_value_image">{{ $t('labels.frontend.nutrition_value') }}</p>
            <p class="body-1" v-if="!herb.nutritional_value_image" v-html="herb.chemical_composition.replace(new RegExp('\r?\n','g'), '<br >')"></p>
            <p :style="{'padding-top': `${herb.nutritional_value_image ? '20px' : ''}`}"
               class="display-1">{{ $t('labels.frontend.health_benefits') }}</p>
            <p class="body-1 justification" v-html="herb.medical_usages.replace(new RegExp('\r?\n','g'), '<br >')"></p>
            <p class="display-1">{{ $t('labels.frontend.other_uses') }}</p>
            <p class="body-1 justification" v-html="herb.other_usages.replace(new RegExp('\r?\n','g'), '<br >')"></p>
            <p class="display-1">{{ $t('labels.frontend.warnings') }}</p>
            <p class="body-1 justification" v-html="herb.warnings.replace(new RegExp('\r?\n','g'), '<br >')"></p>
          </v-col>
          <v-col cols="12" sm="3" v-if="herb.nutritional_value_image">
            <v-img :src="herb.nutritional_value_image" max-width="300" contain class="mx-auto"></v-img>
          </v-col>
        </v-row>
        <v-row v-if="images.length > 0">
          <v-col cols="10" offset="1">
            <v-card>
              <v-container fluid>
                <v-row>
                  <v-col
                    v-for="(image,i) in images"
                    :key="i"
                    class="d-flex child-flex"
                    cols="12"
                    sm="4"
                  >
                    <v-card flat tile class="d-flex">
                      <v-img
                        :src="image"
                        :lazy-src="image"
                        aspect-ratio="1"
                        class="grey lighten-2"
                        @click="index = i"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <gallery :images="images" :index="index" @close="index = null" v-if="images.length > 0"></gallery>
        <v-row>
          <v-col cols="10" sm="7" offset="1">
            <p class="display-1">{{ $t('labels.frontend.references') }}</p>
            <ul>
              <li v-for="(link, i) in herb.links"
                  :key="i"><a :href="link.link" class="body-1">{{ link.title }}</a></li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
    <fe-footer></fe-footer>
  </v-app>
</template>
<script>
import axios from 'axios'
import gallery from 'vue-gallery'
import FeFooter from './FeFooter'

export default {
  name: 'Herb',
  components: {
    FeFooter,
    gallery
  },
  props: {
    id: {
      type: Number | String,
      required: true
    },
    slug: {
      type: String,
      required: false,
      default: ''
    }
  },
  metaInfo () {
    return {
      title: this.herb ? this.herb.name : null,
      titleTemplate: `%s | ${this.$t('labels.frontend.herbs_encyclopedia')}`,
      meta: [
        { vmid: 'description', name: 'description', content: this.herb ? this.herb.description : null }
      ]
    }
  },
  data: () => ({
    show: false,
    herb: null,
    search: null,
    images_loaded: false,
    index: null,
    fab: null,
    socials: [
      {
        icon: 'mdi-facebook',
        color: 'indigo',
        link: 'https://www.facebook.com/HerbsEncyclopedia/'
      },
      {
        icon: 'mdi-instagram',
        color: 'red lighten-3',
        link: 'https://www.instagram.com/herbsencyclopedia/'
      },
      {
        icon: 'mdi-twitter',
        color: '#26c6da',
        link: 'https://twitter.com/herbs_app'
      }
    ]
  }),
  computed: {
    images () {
      if (this.herb.gallery_images.length > 0) return this.herb.gallery_images.map((o) => o['url'])
      else return this.herb.slider
    }
  },
  async created () {
    let { data } = await axios.get(this.$app.route(`web.api.herbs.show`, { herb: this.id }), { params: { include: 'links' } })
    this.herb = data
    if (this.herb.name === null) {
      this.$router.push({ name: 'frontend.herbs' })
    }
    this.get_all_images()
  },
  mounted () {
    // console.log(this.$route.params)
  },
  methods: {
    goToSearch () {
      this.$router.push({ name: 'frontend.herbs', query: { search: this.search } })
    },
    async get_all_images () {
      let { data } = await axios.get(this.$app.route(`api.herbs.gallery`, { herb: this.id }))
      this.herb.gallery_images = data.data
    }
  }
}
</script>

<style scoped>
    /deep/ a:not([href]):not([tabindex]) {
        color: white !important;
    }
</style>

<style>
    .justification {
        text-align: justify
    }
</style>
